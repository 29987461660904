import {useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useDeepCompareEffect} from 'react-use';

import {useToast} from 'client/common/hooks/useToast';

import {AppendToastNotification} from 'client/models/toast-notification/types';
import {ApiAction, ApiDispatch, MainStates} from 'client/types';

type UseReduxFetchConfig<TData> = {
  action: ApiAction<TData>;
  actionArgs?: any;
  selector?: (state: MainStates) => TData;
  fetchOnMount?: boolean;
  skip?: boolean;
  toasts?: {
    error?: Omit<AppendToastNotification, 'type'>;
    success?: Omit<AppendToastNotification, 'type'>;
  };
};

// useDeepCompareEffect sends warning in console, because of primitive variables.
// this object is not primitive and will be used in dependencies in useDeepCompareEffect
const FOR_PROTECTION = {};

const useReduxFetch = <TData = void,>(config: UseReduxFetchConfig<TData>) => {
  const {action, actionArgs, skip, fetchOnMount = true, selector, toasts} = config;

  const [loading, setLoading] = useState(fetchOnMount && !skip);
  const [fetchResult, setFetchResult] = useState<TData>();

  const dispatch: ApiDispatch = useDispatch();
  const {appendToastNotification} = useToast();

  const data = useSelector((state: MainStates) => selector?.(state));

  const fetch = useCallback(
    (
      ...args: any[]
    ): Promise<{
      error?: boolean;
      payload: TData;
    }> => {
      setLoading(true);
      const spreadArr = [actionArgs].flat();
      return dispatch(args.length ? action(...args) : action(...spreadArr))
        .then((res) => {
          setFetchResult(res.payload);
          if (toasts?.success) {
            appendToastNotification({
              type: 'success',
              ...toasts.success,
            });
          }
          return res;
        })
        .catch((res) => {
          if (toasts?.error) {
            appendToastNotification({
              type: 'error',
              ...toasts.error,
            });
          }
          return res;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [action, actionArgs, appendToastNotification, dispatch, toasts?.error, toasts?.success],
  );

  useDeepCompareEffect(() => {
    if (fetchOnMount && !skip) {
      const spreadArr = [actionArgs].flat();
      fetch(...spreadArr);
    }
  }, [fetchOnMount, actionArgs, skip, FOR_PROTECTION]);

  return {
    loading,
    fetch,

    // This type is for detecting returning type of data when selector is not provided
    data: (selector ? data : fetchResult) as typeof selector extends never ? typeof fetchResult : typeof data,
  };
};

export default useReduxFetch;
