import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {isEmpty} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {selectTemplateForm, selectCurrentTemplateErrors} from 'client/ducks/templates/selectors';

import Accordion from 'client/common/accordion';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import DiyOpAccessLevelPopover from 'client/components/diy-operation/popovers/diy-op-access-level-popover';
import {TranslationJsx} from 'client/models/language/types';

import FormItem from '../form-item';
import {isFormItemEmpty} from '../form-item/helpers';

import cssModule from './form-section.module.scss';

const b = bem('form-section', {cssModule});

const FormSection = (props) => {
  const {
    id,
    pageId,
    title,
    help,
    icon,
    formItems,
    setFormData,
    templateId,
    accessParams,
    setFormAccessLevel,
    checkFormItem,
    access,
    formItemsAccesses,
    isAccessGranted = false,
    comments,
    variables,
  } = props;

  const lang = useLanguage(
    'DIY_OPERATION.MODALS.CONFIGURATION_MODAL.DIY_CUSTOMIZATION_FORM.FORM_ELEMENTS.FORM_SECTION',
  );
  const formId = useSelector(selectTemplateForm).id;
  const formErrors = useSelector(selectCurrentTemplateErrors);

  const visibleFormItems = useMemo(() => {
    return formItems.filter((el) => {
      const {visible} = checkFormItem(el, {});
      return visible && !isFormItemEmpty(el);
    });
  }, [checkFormItem, formItems]);

  const {visible} = checkFormItem(accessParams, {lang: '', device: ''});

  if (!visible || !visibleFormItems.length) {
    return null;
  }

  const sectionErrors = formErrors?.[`'${pageId}'`]?.[`'${id}'`];
  const hasErrors = sectionErrors ? !isEmpty(sectionErrors) : false;

  const getAccessByFormItemId = (idItem) => formItemsAccesses.find((item) => item.id === idItem);
  const getFormItemUiId = (itemId) => `f${formId}p${pageId}s${id}i${itemId}`;

  return (
    <Accordion
      className={b()}
      title={title}
      iconName={icon}
      disabled={hasErrors}
      tooltips={
        <div className={b('tooltips')}>
          {help && (
            <Popover overlayInnerStyle={{width: 200}} overlay={<div>{help}</div>}>
              <Icon name="question" className={b('icon-popover')} width={20} height={20} />
            </Popover>
          )}
          {isAccessGranted && access && (
            <DiyOpAccessLevelPopover
              access={access}
              title={lang.ACCESS_LEVEL_TITLE}
              description={lang.ACCESS_LEVEL_DESCRIPTION}
              onChange={(value) => {
                setFormAccessLevel?.({value, formSectionId: id, type: 'formSection'});
              }}
            />
          )}
        </div>
      }
    >
      {comments && <p className={b('comments')}>{comments}</p>}
      <div className={b('form-items')}>
        {visibleFormItems.map((item) => (
          <FormItem
            key={item.id}
            formId={formId}
            formItem={item}
            uiId={getFormItemUiId(item.id)}
            access={getAccessByFormItemId(item.id).access}
            templateId={templateId}
            setFormData={(value, err, params = {}) => setFormData({...value, formSectionId: id}, err, params)}
            setFormAccessLevel={(value) => {
              setFormAccessLevel?.({...value, formSectionId: id});
            }}
            checkFormItem={checkFormItem}
            isAccessGranted={isAccessGranted}
            variables={variables}
          />
        ))}
      </div>
    </Accordion>
  );
};

FormSection.propTypes = {
  id: PropTypes.number.isRequired,
  pageId: PropTypes.number.isRequired,
  templateId: PropTypes.number,
  title: TranslationJsx,
  help: TranslationJsx,
  comments: TranslationJsx,
  icon: PropTypes.string,
  access: PropTypes.string.isRequired,
  accessParams: PropTypes.shape({
    access: PropTypes.string,
    kiosk_only: PropTypes.bool,
    online_only: PropTypes.bool,
    default_hide: PropTypes.bool,
    display_type: PropTypes.bool,
    form_conditions: PropTypes.array,
    conditions_operator: PropTypes.string,
    template_answers: PropTypes.array,
  }),
  formItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_type: PropTypes.string,
    }),
  ).isRequired,
  setFormData: PropTypes.func.isRequired,
  setFormAccessLevel: PropTypes.func,
  isAccessGranted: PropTypes.bool,
  variables: PropTypes.array.isRequired,
};

FormSection.defaultProps = {
  title: 'No title',
  comments: '',
  icon: '',
};

export default FormSection;
