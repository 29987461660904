import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {ToggleField} from 'client/common/fields';
import CheckboxField from 'client/common/fields/checkbox-field';
import NumberField from 'client/common/fields/number-field';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';

import LotteryLossResultAnnouncement from 'client/components/lottery/fieldsets/lottery-loss-result-announcement';
import {LotteryPrizeModalFormName} from 'client/components/lottery/modals/lottery-prize-modal';
import {Translation} from 'client/models/language/types';
import {PRIZE_TYPES} from 'client/models/prizes/constants';

import cssModule from './main-params-fieldset.module.scss';

const b = bem('main-params-fieldset', {cssModule});

type MainParamsFieldsetProps = {
  disabled: boolean;
};

const MainParamsFieldset: React.FC<MainParamsFieldsetProps> = (props) => {
  const {disabled} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL.MAIN_PARAMS_FIELDSET');
  const client = useSelector(selectCurrentClient);
  const autotask = useSelector(selectAutotask);

  const {
    formValues: {mainParams = {}},
    change,
  } = useReduxForm(LotteryPrizeModalFormName);

  const getTypeOptions = () => {
    const result: {value: string; label: Translation}[] = [{value: PRIZE_TYPES.COMMON, label: lang.COMMON}];
    if (client.loyalty_program_id) {
      result.push({value: PRIZE_TYPES.LOYALTY, label: lang.LOYALTY});
    }
    return result;
  };

  const handleTypeChange = (type: string) => {
    if (type === PRIZE_TYPES.LOYALTY) {
      change('mainParams.loyalty_only', true);
    }
  };

  return (
    <div className={b()}>
      <div className={b('type-row')}>
        <SelectField
          name="mainParams.type"
          label={lang.PRIZE_TYPE}
          className={b('prize-type')}
          options={getTypeOptions()}
          onChange={handleTypeChange}
          simpleValue
          disabled={disabled}
        />
        {mainParams.type === PRIZE_TYPES.LOYALTY && (
          <div className={b('loyalty-fields')}>
            <NumberField name="mainParams.loyalty_amount" label={lang.AMOUNT} disabled={disabled} required={true} />
            <TextField name="mainParams.loyalty_unit" label={lang.UNIT} disabled={disabled} />
          </div>
        )}
      </div>
      {client.loyalty_program_id && (
        <CheckboxField
          label={lang.LOYALTY_ONLY}
          name="mainParams.loyalty_only"
          inversionColor
          disabled={disabled}
          withWrap
        />
      )}
      <div className={b('row')}>
        <NumberField
          name="mainParams.level"
          label={lang.LEVEL}
          className={b('level-field')}
          disabled={autotask.validated}
        />
        <ToggleField
          label={lang.DISTRIBUTION}
          rounded
          className={b('toggle-distribution')}
          name="mainParams.distribution"
          disabled={autotask.validated}
        />
      </div>
      <LotteryLossResultAnnouncement
        title={lang.WIN_ANNOUNCEMENT}
        className={b('win-announcement')}
        name="mainParams"
        isReadOnly={disabled}
      />
    </div>
  );
};

export default MainParamsFieldset;
