import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';
import {hasObjectTruthyValues} from 'client/services/validator';

import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {createDiyPrize, updateDiyPrize, deleteDiyPrize, deleteDiyPrizeCoupon} from 'client/ducks/prizes/actions';

import AppButton from 'client/common/buttons/app-button';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import {PRIZE_TYPES} from 'client/components/prizes/constants';
import {isCoupon} from 'client/components/prizes/helpers';

import getInitialValues from './initialValues';
import mapFormValues from './mapFormValues';
import PrizeDiyModalCoupon from './prize-diy-modal-coupon';
import PrizeDiyModalGeneral from './prize-diy-modal-general';
import PrizeDiyModalInstantWin from './prize-diy-modal-instant-win';
import validate from './validate';

import './prize-diy-modal.scss';

const b = bem('prize-diy-modal');

const tabs = {
  GENERAL: 'general',
  INSTANT_WIN: 'instant_win',
  COUPONS: 'coupons',
};

const PrizeDiyModal = (props) => {
  const {
    game,
    operation,
    fetchData,
    onClose,
    handleSubmit,
    isEdit,
    editingPrize,
    invalid,
    isOperationArchived,
    submitFailed,
  } = props;
  const dispatch = useDispatch();
  const lang = useLanguage('PRIZES.MODALS.PRIZE_DIY_MODAL');

  const [activeTab, setActiveTab] = useState(tabs.GENERAL);
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const autotask = useSelector(selectAutotask);

  const {formValues, errors} = useReduxForm(PrizeDiyModal.formName);

  const {appendToastNotification} = useToast();
  const gameId = game.id;

  const disabled = !operation.editable || isOperationArchived;

  const handleDeleteCoupon = () => dispatch(deleteDiyPrizeCoupon(editingPrize.coupon.id));

  const handleSave = async (values) => {
    try {
      const body = await mapFormValues(values);
      const data = {
        prize: {
          ...body,
          game_id: gameId,
          order: values.order ?? game.prizes.length,
          ...(!isEdit && {code: operation.code}),
        },
      };

      if (isCoupon(data.prize)) {
        data.prize.coupon.operation_id = operation.id;
      }

      toggleLoading();

      const response = await dispatch(isEdit ? updateDiyPrize(editingPrize.id, data) : createDiyPrize(data));

      if (isEdit && isCoupon(editingPrize) && !isCoupon(data.prize)) {
        await handleDeleteCoupon();
      }

      await fetchData();
      onClose();

      if (response?.payload?.prize?.token_list_errors?.filter((i) => !i.common)?.length) {
        appendToastNotification({
          type: 'warning',
          description: lang.PRIZE_TOKEN_ERROR,
        });
      }
      appendToastNotification({
        type: 'success',
        description: lang.PRIZE_SAVED_SUCCESSED,
      });
    } catch (error) {
      appendToastNotification({
        type: 'error',
        description: lang.PRIZE_NO_SAVED,
      });
    }
  };

  const handleDelete = async () => {
    if (isCoupon(editingPrize)) {
      await handleDeleteCoupon();
    }

    await dispatch(deleteDiyPrize(editingPrize.id));
    await fetchData();
    onClose();
  };

  const generalTabErrors = {
    ...errors.main,
    ...errors.win_pop_up,
    ...errors.segmentation,
    ...errors.ticket,
    ...errors.email,
  };

  const instantWinTabErrors = {
    ...errors.ratioTime,
    ...errors.ratioParticipation,
    ...errors.customList,
    ...errors.weeklyList,
    ...errors.weekSchedule,
  };

  const hasGeneralTabErrors = submitFailed && hasObjectTruthyValues(generalTabErrors);
  const hasInstantWinTabErrors = submitFailed && hasObjectTruthyValues(instantWinTabErrors);
  const hasCouponTabErrors = submitFailed && hasObjectTruthyValues(errors.coupon || {});

  const tabsConfig = useMemo(
    () => [
      {
        title: lang.GENERAL,
        value: tabs.GENERAL,
        hasError: hasGeneralTabErrors,
      },
      {
        title: lang.INSTANT_WIN,
        value: tabs.INSTANT_WIN,
        hasError: hasInstantWinTabErrors,
      },
      {
        title: lang.COUPONS,
        value: tabs.COUPONS,
        exclude: formValues?.main?.type !== PRIZE_TYPES.COUPON,
        hasError: hasCouponTabErrors,
      },
    ],
    [lang, formValues?.main?.type, hasGeneralTabErrors, hasInstantWinTabErrors, hasCouponTabErrors],
  );

  const hasWins = Boolean(
    editingPrize?.total_quantities?.total_prize_won_non_validated ||
      editingPrize?.total_quantities?.total_prize_won_validated,
  );

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose} isCloseHidden>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <div className={b('buttons')}>
          {isEdit && (
            <>
              <ConfirmationModal
                show={showDeleteModal}
                onClose={toggleDeleteModal}
                onConfirm={handleDelete}
                message={lang.DELETE_THIS_PRIZE}
                buttonCancel={{
                  label: lang.CANCEL,
                  color: 'devices',
                  transparent: true,
                  size: 'small',
                }}
                buttonConfirm={{
                  label: lang.OK,
                  color: 'devices',
                  size: 'small',
                }}
                clientSide
              />
              <AppButton
                label={lang.DELETE_PRIZE}
                onClick={toggleDeleteModal}
                color="error"
                transparent
                disabled={disabled || hasWins}
              />
            </>
          )}
          <AppButton label={lang.CANCEL} onClick={onClose} transparent />
          <AppButton
            label={lang.SAVE}
            className={b('save-button')}
            loading={loading}
            disabled={autotask.validated}
            submit
          />
        </div>
        {invalid && <p className={b('error')}>{lang.FILL_ALL_FIELDS}</p>}
        <TabSwitcher
          className={b('tab-switcher')}
          tabs={tabsConfig}
          activeTab={activeTab}
          onTabClick={setActiveTab}
          style="title"
        />
        <div className={b('content')}>
          <Offscreen hidden={activeTab !== tabs.GENERAL}>
            <PrizeDiyModalGeneral prize={editingPrize} disabled={disabled} hasWins={hasWins} />
          </Offscreen>
          <Offscreen hidden={activeTab !== tabs.INSTANT_WIN}>
            <PrizeDiyModalInstantWin disabled={disabled} />
          </Offscreen>
          <Offscreen hidden={activeTab !== tabs.COUPONS}>
            <PrizeDiyModalCoupon operation={operation} disabled={disabled} />
          </Offscreen>
        </div>
      </form>
    </Modal>
  );
};

export const PrizeDiyModalFormName = 'DIY_PRIZE_MODAL_FORM';
PrizeDiyModal.formName = PrizeDiyModalFormName;

PrizeDiyModal.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.number.isRequired,
    prizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  editingPrize: PropTypes.object,
  validationLang: PropTypes.object.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isOperationArchived: PropTypes.bool.isRequired,
};

PrizeDiyModal.defaultProps = {
  isEdit: false,
  editingPrize: null,
};

export default connect((state, {editingPrize}) => ({
  validationLang: state.languageState.payload.PRIZES.FIELDSETS.ERRORS,
  isEdit: !!editingPrize,
  initialValues: getInitialValues(editingPrize, state),
  autotaskTypes: selectAutotaskTypes(state),
}))(
  reduxForm({
    form: PrizeDiyModal.formName,
    shouldValidate: () => true,
    forceUnregisterOnUnmount: false,
    validate,
  })(PrizeDiyModal),
);
